import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Nav, Navbar as NavbarBs } from "react-bootstrap";
import "./Navbar.css";
import { useChangeBackGroundColor } from "../context/ChangeBackGroundColorContext";
import { useChangeLanguage } from "../context/ChangeLanguageContext";
import { useChosenMenu } from "../context/ChangeMenuContext";
import logo1 from "../logoImages/Logo-2.png";
import logo2 from "../logoImages/Logo-3.gif";

const PhoneNavbar = () => {
  const { ChangeColor, backgroundColor } = useChangeBackGroundColor();
  const { changeLanguage, language } = useChangeLanguage();
  const [showTheMenu, setShowTheMenu] = useState(false);
  const { chosenMenu, ChangeMenu } = useChosenMenu();
  const [logo, setLogo] = useState(logo1);
  const navigate = useNavigate();

  // Function to start cycling images
  const startCycling = () => {
    setLogo(logo2);
  };

  // Function to stop cycling images
  const stopCycling = () => {
    setLogo(logo1);
  };

  return (
    <div
      className="mainPhoneNavBar"
      style={{ backgroundColor: backgroundColor }}
    >
      <NavbarBs className="p-1">
        <Nav className="d-flex w-100">
          <NavLink
            to={""}
            style={{ width: "30%" }}
            className="d-flex justify-content-start align-items-center"
            onClick={() => {
              ChangeColor("#F3F3F3");
              ChangeMenu(0);
            }}
          >
            <img
              src={logo}
              onTouchStart={startCycling}
              onTouchEnd={stopCycling}
              alt="logo"
              style={{
                maxWidth: "68px",
              }}
            />
          </NavLink>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: "85%" }}
          >
            <div className="d-flex justify-content-center align-items-center">
              <a href="https://www.google.com" target="_blank" rel="noreferrer">
                <img
                  src="/facebookLogo.svg"
                  style={{ width: "28px", height: "28px", marginRight: "8px" }}
                  alt="logo"
                  width={170}
                />
              </a>
              <a href="https://www.google.com" target="_blank" rel="noreferrer">
                <img
                  src="/instagramLogo.svg"
                  style={{ width: "28px", height: "28px", marginRight: "8px" }}
                  alt="logo"
                  width={170}
                />
              </a>
              <a href="https://www.google.com" target="_blank" rel="noreferrer">
                <img
                  src="/youtubeLogo.svg"
                  style={{ width: "28px", height: "28px" }}
                  alt="logo"
                  width={170}
                />
              </a>
            </div>
            {/* <div className="d-flex justify-content-center align-items-center">
              <Nav.Link
                as={NavLink}
                to="/checkout"
                className=""
                onClick={() => ChangeColor("#EFEAD1")}
              >
                <img
                  src="/shopping-bag.svg"
                  style={{ width: "26px", height: "26px", marginRight: "8px" }}
                  alt="logo"
                  width={170}
                />{" "}
              </Nav.Link>
            </div> */}
            <div
              className="d-flex justify-content-center align-items-center "
              onClick={() => setShowTheMenu(true)}
            >
              <img
                src="/hamburger.svg"
                style={{ width: "47px", height: "22px" }}
                alt="logo"
                width={170}
              />
            </div>
          </div>
        </Nav>
      </NavbarBs>
      {showTheMenu && (
        <>
          <div
            style={{
              backgroundColor: backgroundColor,
              opacity: "10%",
              position: "fixed",
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
              zIndex: 1000,
            }}
            onClick={() => setShowTheMenu(false)}
          ></div>
          <div className="w-100 ">
            <NavbarBs
              className="d-flex justify-content-center align-items-start"
              style={{
                backgroundColor: backgroundColor,
                position: "fixed",
                left: 0,
                top: 0,
                right: 0,
                zIndex: 10000,
              }}
            >
              <Nav
                className={
                  language === "ar"
                    ? "d-flex justify-content-center flex-column "
                    : "d-flex flex-column align-items-center  "
                }
                style={{ width: "100vw" }}
              >
                <div className="d-flex w-100 openedMenu">
                  <NavLink
                    to={""}
                    style={{ width: "30%" }}
                    className="d-flex justify-content-start align-items-center"
                    onClick={() => {
                      ChangeColor("#F3F3F3");
                      setShowTheMenu(false);
                      ChangeMenu(0);
                    }}
                  >
                    <img
                      src={logo}
                      onTouchStart={startCycling}
                      onTouchEnd={stopCycling}
                      alt="logo"
                      style={{
                        maxWidth: "68px",
                      }}
                    />
                  </NavLink>
                  {language === "ar" ? (
                    <div
                      style={{ width: "68%" }}
                      className="d-flex flex-column justify-content-center fromThePeopleTextStyling mb-3"
                      onClick={() => {
                        ChangeColor("#F3F3F3");
                        navigate("/aboutus");
                        setShowTheMenu(false);
                      }}
                    >
                      <div
                        style={{ width: "100%" }}
                        className="d-flex flex-row justify-content-between"
                      >
                        <span className="phoneTextSpan">ـناس</span>
                        <span className="phoneTextSpan">الـ</span>
                        <span className="phoneTextSpan">من</span>
                      </div>
                      <div
                        style={{ width: "97%" }}
                        className="d-flex flex-row justify-content-between align-self-end"
                      >
                        <span className="phoneTextSpan">ـناس</span>
                        <span
                          className="phoneTextSpan"
                          style={{ marginRight: "2rem" }}
                        >
                          الـ
                        </span>
                        <span className="phoneTextSpan">إلى</span>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{ width: "68%" }}
                      className="d-flex flex-column justify-content-center "
                      onClick={() => {
                        ChangeColor("#F3F3F3");
                        navigate("/aboutus");
                        setShowTheMenu(false);
                      }}
                    >
                      <div
                        style={{ width: "100%" }}
                        className="d-flex flex-row justify-content-between"
                      >
                        <span>For</span>
                        <span>Ze</span>
                        <span>Beoble</span>
                      </div>
                      <div
                        style={{ width: "97%" }}
                        className="d-flex flex-row justify-content-between"
                      >
                        <span>By</span>
                        <span style={{ marginLeft: "2rem" }}>Ze</span>
                        <span>Beoble</span>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <Nav.Link
                    as={NavLink}
                    to="/events"
                    className="TextStyling text-center "
                    style={{ fontWeight: chosenMenu === 1 ? "bold" : 400 }}
                    onClick={() => {
                      ChangeColor("#FFDBD8");
                      setShowTheMenu(false);
                      ChangeMenu(1);
                    }}
                  >
                    {language === "ar" ? "فعاليات" : "Events"}
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/store"
                    className="TextStyling text-center"
                    style={{ fontWeight: chosenMenu === 2 ? "bold" : 400 }}
                    onClick={() => {
                      ChangeColor("#EFEAD1");
                      setShowTheMenu(false);
                      ChangeMenu(2);
                    }}
                  >
                    {language === "ar" ? "المتجر" : "Store"}
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/archive"
                    className="TextStyling text-center"
                    style={{ fontWeight: chosenMenu === 3 ? "bold" : 400 }}
                    onClick={() => {
                      ChangeColor("#C0D1BF");
                      setShowTheMenu(false);
                      ChangeMenu(3);
                    }}
                  >
                    {language === "ar" ? "الأرشيف" : "Archive"}
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/partners"
                    className="TextStyling text-center"
                    style={{ fontWeight: chosenMenu === 4 ? "bold" : 400 }}
                    onClick={() => {
                      ChangeColor("#B5B9C4");
                      setShowTheMenu(false);
                      ChangeMenu(4);
                    }}
                  >
                    {language === "ar" ? "شركائنا" : "Partners"}
                  </Nav.Link>
                </div>
                <div className={language === "ar" ? "d-flex flex-column" : ""}>
                  <p
                    className="TextStyling text-center"
                    style={{ fontWeight: language === "ar" ? "bold" : 400 }}
                    onClick={() => {
                      changeLanguage("ar");
                      setShowTheMenu(false);
                    }}
                  >
                    عربي
                  </p>
                  <p
                    className="TextStyling text-center"
                    style={{ fontWeight: language === "en" ? "bold" : 400 }}
                    onClick={() => {
                      changeLanguage("en");
                      setShowTheMenu(false);
                    }}
                  >
                    English
                  </p>
                </div>
              </Nav>
            </NavbarBs>
          </div>
        </>
      )}
    </div>
  );
};

export default PhoneNavbar;
