import { useChangeLanguage } from "../context/ChangeLanguageContext";
import "./Partners.css";
import { useEffect, useState } from "react";
import { baseUrl } from "../DataUrl";
import { DisplayTextWithNewLines } from "../utailities/FIxTheText";
import partner1 from "../logoImages/Malmostad_logo.jpg";
import partner2 from "../logoImages/Malmostad_logo.jpg";
import partner3 from "../logoImages/Malmostad_logo.jpg";

const partners = [
  { id: 1, logo: partner1, name: "Partner 1" },
  { id: 2, logo: partner2, name: "Partner 2" },
  { id: 4, logo: partner3, name: "Partner 3" },
  { id: 5, logo: partner3, name: "Partner 3" },
  { id: 6, logo: partner3, name: "Partner 3" },
  { id: 7, logo: partner3, name: "Partner 3" },
  { id: 8, logo: partner3, name: "Partner 3" },
  { id: 9, logo: partner3, name: "Partner 3" },
  { id: 10, logo: partner3, name: "Partner 3" },
  { id: 11, logo: partner3, name: "Partner 3" },
  { id: 12, logo: partner3, name: "Partner 3" },
];

const Partners = () => {
  const { language } = useChangeLanguage();
  const [partnersData, setPartnersData] = useState("");
  const [partnersLogos, setPartnernsLogos] = useState<string[]>([]);

  const getData = async () => {
    await fetch(`${baseUrl}/api/partners`)
      .then((response) => response.json())
      .then((datab) => {
        language === "ar"
          ? setPartnersData(datab.ar)
          : setPartnersData(datab.en);
        setPartnernsLogos(datab.imagesUrl);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getData();
  }, [language]);

  return (
    <div>
      <h1
        className="headText"
        style={{ textAlign: language === "ar" ? "right" : "left" }}
      >
        {language === "ar" ? "شركائنا" : "Our Partners"}
      </h1>
      <p
        className="paragraphText"
        style={{
          textAlign: language === "ar" ? "right" : "left",
          direction: language === "ar" ? "rtl" : "ltr",
        }}
      >
        <DisplayTextWithNewLines text={partnersData} />
      </p>
      <div className="partner-logo-dev">
        {partnersLogos?.map((partner, i) => (
          <div key={i}>
            <img src={partner} alt={`logo ${i}`} className="partner-logo " />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;
