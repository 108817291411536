import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import axios from "axios";
import "./AddArchive.css";
import VerificationIcon from "./VerificationIcon";
import { baseUrl } from "../DataUrl";
interface ArchivePropsType {
  title: string;
  designedBy: string;
  description: string;
  ProductPrice: string;
  ProductLink: string;
}

type AddArchivePropsType = {
  edit: boolean;
  productId: string | null;
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>> | null;
};

const AddProduct: React.FC<AddArchivePropsType> = ({
  edit,
  productId,
  setShowEdit,
}) => {
  const [sharedImages, setSharedImages] = useState<File[]>([]);
  const [showIcon, setShowIcon] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState<{
    en: ArchivePropsType;
    ar: ArchivePropsType;
    imagesUrl: string[];
  }>({
    en: {
      title: "",
      description: "",
      ProductPrice: "",
      designedBy: "",
      ProductLink: "",
    },
    ar: {
      title: "",
      description: "",
      ProductPrice: "",
      designedBy: "",
      ProductLink: "",
    },
    imagesUrl: [],
  });

  const getData = async (id: string) => {
    await fetch(`${baseUrl}/api/products/${id}`)
      .then((response) => response.json())
      .then((datab) => {
        setFormData({ ...datab });
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (edit && productId !== null) {
      getData(productId);
    }
  }, []);

  useEffect(() => {
    if (showIcon) {
      const timer = setTimeout(() => {
        setShowIcon(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showIcon]);

  const handleChange = (
    lang: "en" | "ar",
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [lang]: {
        ...formData[lang],
        [name]: value,
      },
    });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setSharedImages([...sharedImages, ...Array.from(files)]);
    }
  };

  const handleRemoveImage = (index: number) => {
    const newImages = [...sharedImages];
    newImages.splice(index, 1);
    setSharedImages(newImages);
  };

  const handleRemoveImageFrmDatabase = async (
    index: number,
    imageUrl: string
  ) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this image?"
    );

    if (!confirmed) {
      // If the user cancels, do nothing
      return;
    }

    const newImages = [...formData.imagesUrl];
    newImages.splice(index, 1);
    console.log(newImages);
    setFormData({ ...formData, imagesUrl: newImages });
    formData.imagesUrl = newImages;
    const formDataToSend = new FormData();
    const token = localStorage.getItem("authToken");

    formDataToSend.append("data", JSON.stringify(formData));
    formDataToSend.append("imageUrl", JSON.stringify(imageUrl));

    const response = await axios.post(
      `${baseUrl}/api/productDeleteImage/${productId}`,
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": token,
        },
      }
    );
    console.log("Response:", response.data);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("data", JSON.stringify(formData));
    const token = localStorage.getItem("authToken");
    setError(false);
    setErrorMessage("");

    if (sharedImages.length === 0 && formData.imagesUrl.length === 0) {
      setError(true);
      setErrorMessage("Don't forget to add Images.");
      return;
    }

    sharedImages.forEach((file) => {
      formDataToSend.append("images", file);
    });

    console.log("test1");

    try {
      if (edit) {
        const response = await axios.post(
          `${baseUrl}/api/products/${productId}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-access-token": token,
            },
          }
        );
        if (response.status === 200) {
          setShowIcon(true);
        }
      } else {
        const response = await axios.post(
          `${baseUrl}/api/addProduct`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-access-token": token,
            },
          }
        );
        if (response.status === 200) {
          setShowIcon(true);
          //   setFormData({
          //     en: {
          //       title: "",
          //       description: "",
          //       ProductPrice: "",
          //       designedBy: "",
          //       ProductLink: "",
          //     },
          //     ar: {
          //       title: "",
          //       description: "",
          //       ProductPrice: "",
          //       designedBy: "",
          //       ProductLink: "",
          //     },
          //     imagesUrl: [],
          //   });
          setSharedImages([]);
        }
      }
    } catch (error) {
      console.error("Error uploading archive:", error);
    }
  };

  return (
    <>
      {showIcon && <VerificationIcon />}
      <form onSubmit={handleSubmit}>
        {edit && (
          <button onClick={() => setShowEdit && setShowEdit(false)}>
            Back to edit
          </button>
        )}
        <h2>Add Product</h2>

        <section>
          <label>Images:</label>
          <input type="file" multiple onChange={handleFileChange} />
          <div className="images-preview">
            {sharedImages.map((file, index) => (
              <div key={index} className="image-preview">
                <img src={URL.createObjectURL(file)} alt={` ${index + 1}`} />
                <button
                  type="button"
                  className="remove-button"
                  onClick={() => handleRemoveImage(index)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        </section>
        {edit && (
          <section>
            <div className="images-preview">
              {formData.imagesUrl.map((url, index) => (
                <div key={index} className="image-preview">
                  <img src={url} alt="img-preview" />
                  <button
                    type="button"
                    className="remove-button"
                    onClick={() => handleRemoveImageFrmDatabase(index, url)}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          </section>
        )}

        <h3>English</h3>
        {renderArchiveForm("en")}

        <h3>العربية</h3>
        {renderArchiveForm("ar")}

        <button type="submit">Submit</button>
        {error && <p className="bg-warning px-3 mt-1">{errorMessage}</p>}
      </form>
    </>
  );

  function renderArchiveForm(lang: "en" | "ar") {
    return (
      <div className={lang === "ar" ? "rtl" : ""}>
        <label>
          {lang === "ar" ? "العنوان" : "Title"}:
          <input
            type="text"
            name="title"
            value={formData[lang].title}
            onChange={(e) => handleChange(lang, e)}
            required
          />
        </label>

        <label>
          {lang === "ar" ? "صمم بواسطة" : "Designed by"}:
          <input
            type="text"
            name="designedBy"
            value={formData[lang].designedBy}
            onChange={(e) => handleChange(lang, e)}
            required
          />
        </label>
        <label>
          {lang === "ar" ? "الوصف" : "Description"}:
          <textarea
            name="description"
            value={formData[lang].description}
            onChange={(e) => handleChange(lang, e)}
            required
          />
        </label>
        <label>
          {lang === "ar" ? "رابط المنتج" : "Product link"}:
          <input
            type="url"
            name="ProductLink"
            value={formData[lang].ProductLink}
            onChange={(e) => handleChange(lang, e)}
          />
        </label>
        <label>
          {lang === "ar" ? "سعر المنتج" : "Product price"}:
          <input
            type="number"
            name="ProductPrice"
            value={formData[lang].ProductPrice}
            onChange={(e) => handleChange(lang, e)}
          />
        </label>
      </div>
    );
  }
};

export default AddProduct;
