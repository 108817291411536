import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import axios from "axios";
import "./AddArticle.css";
import VerificationIcon from "./VerificationIcon";
import { baseUrl } from "../DataUrl";

const EditPartners: React.FC = () => {
  const [sharedImages, setSharedImages] = useState<File[]>([]);
  const [showIcon, setShowIcon] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState<{
    en: string;
    ar: string;
    imagesUrl: string[];
  }>({
    en: "",
    ar: "",
    imagesUrl: [],
  });

  const getData = async () => {
    await fetch(`${baseUrl}/api/partners`)
      .then((response) => response.json())
      .then((datab) => {
        setFormData(datab);
        console.log(datab);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (showIcon) {
      const timer = setTimeout(() => {
        setShowIcon(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showIcon]);

  const handleChange = (
    lang: "en" | "ar",
    e: ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [lang]: value,
    });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setSharedImages([...sharedImages, ...Array.from(files)]);
    }
  };

  const handleRemoveImage = (index: number) => {
    const newImages = [...sharedImages];
    newImages.splice(index, 1);
    setSharedImages(newImages);
  };

  const handleRemoveImageFrmDatabase = async (
    index: number,
    imageUrl: string
  ) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this image?"
    );

    if (!confirmed) {
      // If the user cancels, do nothing
      return;
    }
    const newImages = [...formData.imagesUrl];
    newImages.splice(index, 1);
    console.log(newImages);
    setFormData({ ...formData, imagesUrl: newImages });
    formData.imagesUrl = newImages;
    const formDataToSend = new FormData();
    const token = localStorage.getItem("authToken");

    formDataToSend.append("data", JSON.stringify(formData));
    formDataToSend.append("imageUrl", JSON.stringify(imageUrl));

    const response = await axios.post(
      `${baseUrl}/api/partnersDeleteImage`,
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": token,
        },
      }
    );
    console.log("Response:", response.data);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("data", JSON.stringify(formData));
    const token = localStorage.getItem("authToken");
    setError(false);
    setErrorMessage("");

    if (sharedImages.length === 0 && formData.imagesUrl.length === 0) {
      setError(true);
      setErrorMessage("Don't forget to add Images.");
      return;
    }

    sharedImages.forEach((file) => {
      formDataToSend.append("images", file);
    });

    try {
      const response = await axios.post(
        `${baseUrl}/api/partners`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-token": token,
          },
        }
      );
      console.log("Response:", response.data);
      if (response.status === 200) {
        setShowIcon(true);
      }
    } catch (error) {
      console.error("Error uploading partners data:", error);
    }
  };

  return (
    <>
      {showIcon && <VerificationIcon />}
      <form onSubmit={handleSubmit}>
        <h2>Add Partners Details</h2>

        <section>
          <label>Images:</label>
          <input type="file" multiple onChange={handleFileChange} />
          <div className="images-preview">
            {sharedImages.map((file, index) => (
              <div key={index} className="image-preview">
                <img src={URL.createObjectURL(file)} alt={` ${index + 1}`} />
                <button
                  type="button"
                  className="remove-button"
                  onClick={() => handleRemoveImage(index)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        </section>
        <section className="mt-2">
          <div className="images-preview">
            {formData.imagesUrl.map((url, index) => (
              <div key={index} className="image-preview">
                <img src={url} alt={`Img ${index + 1}`} />
                <button
                  type="button"
                  className="remove-button"
                  onClick={() => handleRemoveImageFrmDatabase(index, url)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        </section>

        <Title title="Edit Partners in English" />
        {renderPartnersForm("en")}

        <Title title="تعديل نص الشركاء" rtl />
        {renderPartnersForm("ar")}

        <button type="submit">Submit</button>
      </form>
    </>
  );

  function Title({ title, rtl }: { title: string; rtl?: boolean }) {
    return <h2 className={rtl ? "rtl" : ""}>{title}</h2>;
  }

  function renderPartnersForm(lang: "en" | "ar") {
    return (
      <div className={lang === "ar" ? "rtl" : ""}>
        <label>
          {lang === "ar" ? "الوصف" : "Description"}:
          <textarea
            name="description"
            value={formData[lang]}
            onChange={(e) => handleChange(lang, e)}
            required
          />
        </label>
      </div>
    );
  }
};

export default EditPartners;
