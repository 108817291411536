import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "./HomeEventsCard.css";
import { useNavigate } from "react-router-dom";
import { useChangeLanguage } from "../context/ChangeLanguageContext";

type homeEventPropsType = {
  id: string;
  backgroundImg: string;
  title: string;
  subTitle: string;
  date: string;
  type: string;
  place: string;
};

const HomeEventsCard = ({
  id,
  backgroundImg,
  date,
  place,
  subTitle,
  title,
  type,
}: homeEventPropsType) => {
  const [isHover, setIsHover] = useState(false);
  const [addBackground, setBackground] = useState("inherit");
  const { language } = useChangeLanguage();
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setIsHover(true);
    const colors = ["#C0D1BF", "#B5B9C4", "#EFEAD1", "#FFDBD8", "#FFDD64"];

    const randomNumber = Math.floor(Math.random() * 8);
    setBackground(colors[randomNumber]);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
    setBackground("inherit");
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use "smooth" for a smooth scroll effect
    });
  };

  const MainDivStyle =
    language === "en"
      ? "d-flex justify-content-between cardBody"
      : "d-flex justify-content-between flex-row-reverse cardBody";

  const imageUrl = `url(${backgroundImg})`;

  return (
    <div className="col-12 col-lg-4  d-flex justify-content-center">
      <Card
        className="mainCard col-4 mb-5"
        style={{
          backgroundColor: addBackground,
          border: "none",
          borderRadius: 0,
          cursor: "pointer",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          navigate("/article?id=" + id);
          scrollToTop();
        }}
      >
        <Card.Img className="img" variant="top" src={backgroundImg} />
        {/* <div
          className="img"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: imageUrl,
          }}
        ></div> */}
        <Card.Body className="cardBodyArticle">
          <div>
            <p
              className="cardTitle mb-2"
              style={{
                textAlign: language === "ar" ? "right" : "left",
              }}
            >
              {title.toLocaleUpperCase()}
            </p>
            <p
              className="cardUnderTitle"
              style={{ textAlign: language === "ar" ? "right" : "left" }}
            >
              {subTitle}
            </p>
          </div>
          <div
            className={MainDivStyle}
            style={{ textAlign: language === "ar" ? "right" : "left" }}
          >
            <div className="d-flex flex-column">
              <span className="mainFooter">
                {language === "en" ? "date:" : ":التاريخ"}
              </span>
              <span className="subFooter">{date}</span>
            </div>
            <div className="d-flex flex-column">
              <span className="mainFooter">
                {language === "en" ? "type:" : ":التصنيف"}
              </span>
              <span className="subFooter">{type}</span>
            </div>
            <div className="d-flex flex-column">
              <span className="mainFooter">
                {language === "en" ? "place:" : ":المكان"}
              </span>
              <span className="subFooter">{place}</span>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default HomeEventsCard;
