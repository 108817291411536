import styled from "styled-components";
import { Nav, Navbar as NavbarBs } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./SideNavbar.css";
import { useChangeBackGroundColor } from "../context/ChangeBackGroundColorContext";
import { useChangeLanguage } from "../context/ChangeLanguageContext";
import { useStoreProduct } from "../context/StoreProductsContext";
import { useEffect, useState } from "react";
import { useChosenMenu } from "../context/ChangeMenuContext";

const SideNavbar = () => {
  const { ChangeColor, backgroundColor } = useChangeBackGroundColor();
  const { changeLanguage, language } = useChangeLanguage();
  const { cartQuantity } = useStoreProduct();
  const { chosenMenu, ChangeMenu } = useChosenMenu();

  return (
    <NavbarBs
      className={
        language === "ar"
          ? "mainSideNavBar justify-content-end align-items-start px-4"
          : "mainSideNavBar justify-content-start align-items-start px-4"
      }
      style={{ backgroundColor: backgroundColor, width: "15%" }}
    >
      <Nav
        className={
          language === "ar"
            ? "d-flex flex-column position-fixed justify-content-between align-items-end"
            : "d-flex flex-column position-fixed justify-content-between"
        }
        style={{ height: "62%", paddingTop: "25px" }}
      >
        <div
          style={{
            textAlign: language === "ar" ? "right" : "left",
          }}
        >
          <Nav.Link
            as={NavLink}
            to="/events"
            className="TextStyling"
            onClick={() => {
              ChangeColor("#FFDBD8");
              ChangeMenu(1);
            }}
            style={{ fontWeight: chosenMenu === 1 ? "bold" : 400 }}
          >
            {language === "ar" ? "فعاليات" : "Events"}
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            to="/store"
            className="TextStyling"
            onClick={() => {
              ChangeColor("#EFEAD1");
              ChangeMenu(2);
            }}
            style={{ fontWeight: chosenMenu === 2 ? "bold" : 400 }}
          >
            {language === "ar" ? "المتجر" : "Store"}
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            to="/archive"
            className="TextStyling"
            onClick={() => {
              ChangeColor("#C0D1BF");
              ChangeMenu(3);
            }}
            style={{ fontWeight: chosenMenu === 3 ? "bold" : 400 }}
          >
            {language === "ar" ? "الأرشيف" : "Archive"}
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            to="/partners"
            className="TextStyling"
            onClick={() => {
              ChangeColor("#B5B9C4");
              ChangeMenu(4);
            }}
            style={{ fontWeight: chosenMenu === 4 ? "bold" : 400 }}
          >
            {language === "ar" ? "شركائنا" : "Partners"}
          </Nav.Link>
        </div>
        {/* <Nav.Link
          as={NavLink}
          to="/checkout"
          className="TextStyling"
          onClick={() => ChangeColor("#EFEAD1")}
        >
          <div
            style={{
              display: "inline-block",
              position: "relative",
            }}
          >
            <img
              src="/shopping-bag.svg"
              style={{
                width: "55px",
                height: "55px",
                marginRight: "8px",
                position: "relative",
              }}
              alt="logo"
              width={170}
            />{" "}
            {cartQuantity > 0 && (
              <div
                className="rounded-circle d-flex justify-content-center align-item-center"
                style={{
                  color: "#1C1C1C",
                  width: "40px",
                  height: "40px",
                  position: "absolute",
                  top: "-20px",
                  right: "3px",
                  transform: "translate(25%, 25%)",
                  backgroundColor: "#FFDD64",
                  border: "2px solid #1C1C1C",
                }}
              >
                <span className="cartQuantityClass align-self-center">
                  {cartQuantity}
                </span>
              </div>
            )}
          </div>
        </Nav.Link> */}
        <div
          className={
            language === "ar" ? "d-flex flex-column align-items-end" : ""
          }
        >
          <p
            className="TextStyling"
            onClick={() => changeLanguage("ar")}
            style={{ fontWeight: language === "ar" ? "bold" : 400 }}
          >
            عربي
          </p>
          <p
            className="TextStyling"
            onClick={() => changeLanguage("en")}
            style={{ fontWeight: language === "en" ? "bold" : 400 }}
          >
            English
          </p>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <a
              href="https://www.facebook.com/ShakoMakoKultur"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/facebookLogo.svg"
                style={{ width: "55px", height: "55px", marginRight: "8px" }}
                alt="logo"
                width={170}
              />
            </a>
            <a
              href="https://www.instagram.com/shakomakokultur/?fbclid=IwY2xjawEdutpleHRuA2FlbQIxMAABHU1sNdCw1SO-FCOg5GqhzwHBOTKwYn7Q-wA6TyfDEkBSVoNqQTlcCKVMcw_aem_BYgRZpsJH3zvgtus6J194g"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/instagramLogo.svg"
                style={{ width: "55px", height: "55px", marginRight: "8px" }}
                alt="logo"
                width={170}
              />
            </a>
            <a href="https://www.google.com" target="_blank" rel="noreferrer">
              <img
                src="/youtubeLogo.svg"
                style={{ width: "55px", height: "55px" }}
                alt="logo"
                width={170}
              />
            </a>
          </div>
        </div>
      </Nav>
    </NavbarBs>
  );
};

export default SideNavbar;
