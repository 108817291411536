import React, { useState } from "react";
import AddArticle from "./AddArticle";
import AddEvent from "./AddEvent";
import AddArchive from "./AddArchive";
import EditPartners from "./EditPartners";
import ArticleEditOrDeleteTable from "./ArticleEditOrDeleteTable";
import EventEditOrDeleteTable from "./EventEditOrDeleteTable";
import ArchiveEditOrDeleteTable from "./ArchiveEditOrDeleteTable";
import SignUp from "./SignUp";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import UserEditOrDeleteTable from "./UserEditOrDeleteTable";
import EditStoreText from "./EditStoreText";
import EditAboutus from "./EditAboutus";
import AddProduct from "./AddProduct";
import ProductEditOrDeleteTable from "./ProductEditOrDeleteTable";

const Home = () => {
  const [displayComponent, setDisplayComponent] = useState(0);
  const { logout, isAdmin } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = () => {
    logout();
    navigate("/login");
  };

  return (
    <div className="container">
      <button onClick={handleSignOut} className="btn btn-danger">
        Logout
      </button>{" "}
      <div className="d-flex w-100 gap-1 flex-wrap">
        <button onClick={() => setDisplayComponent(0)}>
          Add a new article
        </button>
        <button onClick={() => setDisplayComponent(1)}>Edit an article</button>
        <button onClick={() => setDisplayComponent(2)}>Add a new event</button>
        <button onClick={() => setDisplayComponent(3)}>Edit an event</button>
        <button onClick={() => setDisplayComponent(4)}>Add to archive</button>
        <button onClick={() => setDisplayComponent(5)}>Edit archive</button>
        <button onClick={() => setDisplayComponent(6)}>
          Edit partners text
        </button>

        <button onClick={() => setDisplayComponent(7)}>Edit Store</button>
        <button onClick={() => setDisplayComponent(8)}>Add product</button>
        <button onClick={() => setDisplayComponent(9)}>Edit product</button>

        <button onClick={() => setDisplayComponent(10)}>
          Edit About us text
        </button>
        {isAdmin && (
          <>
            <button onClick={() => setDisplayComponent(11)}>
              Create a new user
            </button>
            <button onClick={() => setDisplayComponent(12)}>Users</button>
          </>
        )}
      </div>
      <div className="my-5">
        {displayComponent === 0 && (
          <AddArticle edit={false} articleId={null} setShowEdit={null} />
        )}
        {displayComponent === 1 && <ArticleEditOrDeleteTable />}
        {displayComponent === 2 && (
          <AddEvent edit={false} eventId={null} setShowEdit={null} />
        )}
        {displayComponent === 3 && <EventEditOrDeleteTable />}
        {displayComponent === 4 && (
          <AddArchive edit={false} archiveId={null} setShowEdit={null} />
        )}
        {displayComponent === 5 && <ArchiveEditOrDeleteTable />}
        {displayComponent === 6 && <EditPartners />}
        {displayComponent === 7 && <EditStoreText />}
        {displayComponent === 8 && (
          <AddProduct edit={false} productId={null} setShowEdit={null} />
        )}
        {displayComponent === 9 && <ProductEditOrDeleteTable />}

        {displayComponent === 10 && <EditAboutus />}
        {displayComponent === 11 && (
          <SignUp edit={false} userId={null} setShowEdit={null} />
        )}
        {displayComponent === 12 && <UserEditOrDeleteTable />}
      </div>
    </div>
  );
};

export default Home;
