import React, { useEffect, useState } from "react";
import StoreData from "../data/StoreData.json";
import { useLocation, useNavigate } from "react-router-dom";
import "./ProductDetails.css";
import { baseUrl } from "../DataUrl";
import { useChangeLanguage } from "../context/ChangeLanguageContext";
// import StoreProducts from "../components/StoreProducts";
type StorProductPropsType = {
  title: string;
  designedBy: string;
  description: string;
  ProductPrice: string;
  ProductLink: string;
  imagesUrl: string[];
};

type ProductsYouMightLikesType = {
  id: string;
  imageUrl: string;
};

const ProductDetails = () => {
  const [productData, setProductData] = useState<StorProductPropsType | null>(
    null
  );

  const [productsYouMightLike, setProductsYouMightLike] = useState<
    ProductsYouMightLikesType[] | null
  >(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { language } = useChangeLanguage();

  const getData = async (id: string) => {
    await fetch(`${baseUrl}/api/products/${id}`)
      .then((response) => response.json())
      .then((datab) => {
        language === "ar"
          ? setProductData({ ...datab.ar, imagesUrl: datab.imagesUrl })
          : setProductData({ ...datab.en, imagesUrl: datab.imagesUrl });
      })
      .catch((error) => console.error(error));
  };

  const getSuggestedProducts = async (id: string) => {
    await fetch(`${baseUrl}/api/productsYouMightLike/${id}`)
      .then((response) => response.json())
      .then((datab) => {
        setProductsYouMightLike(datab);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const id = new URLSearchParams(location.search).get("id");
    getData(id!);
  }, [language, location.search]);

  useEffect(() => {
    const id = new URLSearchParams(location.search).get("id");
    getSuggestedProducts(id!);
  }, [location.search]);

  return (
    <div className="d-flex productDetailsMainDiv">
      <div className="productDetailsImgDiv d-flex">
        <img src={productData?.imagesUrl[0]} alt="" style={{ width: "90%" }} />
      </div>
      <div className=" productDetailsDiv pt-4 pt-md-5">
        <h1 className="productName">{productData?.title}</h1>
        <p className="designedBy">{productData?.designedBy}</p>
        <p className="designersDetails my-3 my-md-5">
          {productData?.description}
        </p>
        <div className="d-flex justify-content-between">
          <div>
            <div>
              <h1 className="priceDiv">Kr {productData?.ProductPrice}</h1>
              <p className="TaxDiv">Tax Included</p>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-center">
              <a
                href={productData?.ProductLink}
                className="productAddToCart mt-4"
                target="_blank"
                rel="noreferrer"
              >
                Go to product
              </a>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <p className="moreProducts">You might also like:</p>
          <div className="d-flex justify-content-between row pb-2">
            {productsYouMightLike &&
              productsYouMightLike.map((p) => {
                return (
                  <img
                    key={p.id}
                    src={p.imageUrl}
                    className="productsYouMightLike"
                    alt="productsYouMightLike"
                    onClick={() => navigate(`/productDetails?id=${p.id}`)}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
