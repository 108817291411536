import StoreProducts from "../components/StoreProducts";
import "./store.css";
import { useChangeLanguage } from "../context/ChangeLanguageContext";
import { DisplayTextWithNewLines } from "../utailities/FIxTheText";
import { useEffect, useState } from "react";
import { baseUrl } from "../DataUrl";
import axios from "axios";

type storeDataType = {
  firstParagraph: string;
  secondParagraph: string;
};

type productType = {
  title: string;
  designedBy: string;
  description: string;
  ProductPrice: string;
  ProductLink: string;
};

type productDataType = {
  id: string;
  ar: productType;
  en: productType;
  imagesUrl: string[];
};

const Store = () => {
  const { language } = useChangeLanguage();
  const [storeParagraphsData, setStoreParagraphsData] =
    useState<storeDataType | null>(null);
  const [productsData, setProductsData] = useState<productDataType[] | null>(
    null
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getData = async () => {
    await fetch(`${baseUrl}/api/store`)
      .then((response) => response.json())
      .then((datab) => {
        language === "ar"
          ? setStoreParagraphsData(datab.ar)
          : setStoreParagraphsData(datab.en);
      })
      .catch((error) => console.error(error));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getProducts = async () => {
    const products = await axios.get(`${baseUrl}/api/products`);
    if (language === "ar") {
      setProductsData(products.data);
    } else {
      setProductsData(products.data);
    }
  };

  useEffect(() => {
    getData();
    getProducts();
  }, [language]);

  const classNameLan =
    language === "ar"
      ? "d-flex flex-row-reverse flex-wrap"
      : "d-flex row flex-wrap";

  return (
    <div>
      <div className={classNameLan}>
        {productsData &&
          productsData.length > 0 &&
          productsData.slice(0, 3).map((p) => {
            return language === "ar" ? (
              <StoreProducts
                key={p.id}
                id={p.id}
                by={p.ar.designedBy}
                description={p.ar.description}
                price={p.ar.ProductPrice}
                title={p.ar.title}
                imagesUrl={p.imagesUrl}
                productUrl={p.ar.ProductLink}
              />
            ) : (
              <StoreProducts
                key={p.id}
                id={p.id}
                by={p.en.designedBy}
                description={p.en.description}
                price={p.en.ProductPrice}
                title={p.en.title}
                imagesUrl={p.imagesUrl}
                productUrl={p.en.ProductLink}
              />
            );
          })}
      </div>
      <p
        className="storeP"
        style={{
          textAlign: language === "ar" ? "right" : "left",
          direction: language === "ar" ? "rtl" : "ltr",
        }}
      >
        {storeParagraphsData !== null &&
          (language === "ar" ? (
            <DisplayTextWithNewLines
              text={storeParagraphsData.firstParagraph}
            />
          ) : (
            <DisplayTextWithNewLines
              text={storeParagraphsData.firstParagraph}
            />
          ))}
      </p>
      <div className={classNameLan}>
        {productsData &&
          productsData.length > 2 &&
          productsData.slice(3, 6).map((p) => {
            return language === "ar" ? (
              <StoreProducts
                key={p.id}
                id={p.id}
                by={p.ar.designedBy}
                description={p.ar.description}
                price={p.ar.ProductPrice}
                title={p.ar.title}
                imagesUrl={p.imagesUrl}
                productUrl={p.ar.ProductLink}
              />
            ) : (
              <StoreProducts
                key={p.id}
                id={p.id}
                by={p.en.designedBy}
                description={p.en.description}
                price={p.en.ProductPrice}
                title={p.en.title}
                imagesUrl={p.imagesUrl}
                productUrl={p.en.ProductLink}
              />
            );
          })}
      </div>
      <p
        className="storeP"
        style={{
          textAlign: language === "ar" ? "right" : "left",
          direction: language === "ar" ? "rtl" : "ltr",
        }}
      >
        {storeParagraphsData !== null &&
          (language === "ar" ? (
            <DisplayTextWithNewLines
              text={storeParagraphsData.secondParagraph}
            />
          ) : (
            <DisplayTextWithNewLines
              text={storeParagraphsData.secondParagraph}
            />
          ))}
      </p>
      <div className={classNameLan}>
        {productsData &&
          productsData.length > 5 &&
          productsData.slice(6, productsData.length).map((p) => {
            return language === "ar" ? (
              <StoreProducts
                key={p.id}
                id={p.id}
                by={p.ar.designedBy}
                description={p.ar.description}
                price={p.ar.ProductPrice}
                title={p.ar.title}
                imagesUrl={p.imagesUrl}
                productUrl={p.ar.ProductLink}
              />
            ) : (
              <StoreProducts
                key={p.id}
                id={p.id}
                by={p.en.designedBy}
                description={p.en.description}
                price={p.en.ProductPrice}
                title={p.en.title}
                imagesUrl={p.imagesUrl}
                productUrl={p.en.ProductLink}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Store;
