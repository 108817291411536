import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "./StoreProduct.css";
import { useNavigate } from "react-router-dom";
import { useChangeLanguage } from "../context/ChangeLanguageContext";

type StorProductPropsType = {
  id: string;
  title: string;
  by: string;
  description: string;
  price: string;
  imagesUrl: string[];
  productUrl: string;
};

const StoreProducts = ({
  by,
  description,
  id,
  price,
  title,
  imagesUrl,
  productUrl,
}: StorProductPropsType) => {
  const { language } = useChangeLanguage();
  const navigate = useNavigate();
  return (
    <div className="col-md-4 col-6">
      <Card
        style={{
          border: "none",
          backgroundColor: "#EFEAD1",
        }}
        className="productMainDiv col-4"
      >
        <Card.Img
          className="productImage"
          variant="top"
          src={imagesUrl[0]}
          onClick={() => navigate("/productDetails?id=" + id)}
        />
        <Card.Body>
          <div className="">
            <h1
              className="productHeader"
              style={{
                textAlign: language === "ar" ? "right" : "left",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {title}
            </h1>
            <p
              className="productSubHeader"
              style={{
                textAlign: language === "ar" ? "right" : "left",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {by}
            </p>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="priceDiv">{price} Kr</h1>
                <p
                  className="TaxDiv"
                  style={{
                    textAlign: language === "ar" ? "right" : "left",
                    direction: language === "ar" ? "rtl" : "ltr",
                  }}
                >
                  {language === "ar" ? "تشمل الضرائب" : "Tax Included"}
                </p>
              </div>
              <a
                href={productUrl}
                className="productAddToCart"
                target="_blank"
                rel="noreferrer"
              >
                {language === "ar" ? "إذهب إلى المنتج" : "Go to product"}
              </a>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default StoreProducts;
