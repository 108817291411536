import { useEffect, useState } from "react";
import ArchiveDetails from "../components/ArchiveDetails";
import { useChangeLanguage } from "../context/ChangeLanguageContext";
import { baseUrl } from "../DataUrl";

type archivePropsType = {
  title: string;
  subTitle: string;
  eventLink?: string;
};

type archiveDataType = {
  ar: archivePropsType;
  en: archivePropsType;
  imagesUrl: string[];
};

const Archive = () => {
  const { language } = useChangeLanguage();
  const [archiveData, setArchiveData] = useState<archiveDataType[] | null>(
    null
  );

  const getData = async () => {
    await fetch(`${baseUrl}/api/archive`)
      .then((response) => response.json())
      .then((datab) => {
        setArchiveData(datab);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getData();
  }, [language]);

  return (
    <div>
      {language === "ar" && archiveData !== null
        ? archiveData?.map((a, i) => {
            return (
              <ArchiveDetails
                key={i}
                id={i}
                imagesUrl={a.imagesUrl}
                subTitle={a.ar.subTitle}
                title={a.ar.title}
                eventLink={a.ar.eventLink}
              />
            );
          })
        : archiveData !== null &&
          archiveData?.map((a, i) => {
            return (
              <ArchiveDetails
                key={i}
                id={i}
                imagesUrl={a.imagesUrl}
                subTitle={a.en.subTitle}
                title={a.en.title}
                eventLink={a.en.eventLink}
              />
            );
          })}
    </div>
  );
};

export default Archive;
