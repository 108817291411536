import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Nav, Navbar as NavbarBs } from "react-bootstrap";
import "./Navbar.css";
import { useChangeBackGroundColor } from "../context/ChangeBackGroundColorContext";
import { useChosenMenu } from "../context/ChangeMenuContext";
import logo1 from "../logoImages/Logo-2.png";
import logo2 from "../logoImages/Logo-3.gif";

const Navbar = () => {
  const { ChangeColor, backgroundColor } = useChangeBackGroundColor();
  const { ChangeMenu } = useChosenMenu();
  const [logo, setLogo] = useState(logo1);
  const navigate = useNavigate();
  // Function to start cycling images
  const startCycling = () => {
    setLogo(logo2);
  };

  // Function to stop cycling images
  const stopCycling = () => {
    setLogo(logo1);
  };

  return (
    <div className="mainNavBar" style={{ backgroundColor: backgroundColor }}>
      <NavbarBs
        className="mb-3 navbarDivEng"
        style={{ paddingLeft: "0 !important" }}
      >
        <Nav className="d-flex w-100">
          <div
            style={{ width: "15%" }}
            className="d-flex justify-content-start align-items-center"
            onClick={() => {
              ChangeColor("#F3F3F3");
              ChangeMenu(0);
              navigate("/");
            }}
          >
            <img
              src={logo}
              onMouseEnter={startCycling}
              onMouseLeave={stopCycling}
              alt="logo"
              style={{
                position: "fixed",
                paddingTop: "2rem",
                maxWidth: "205px",
                zIndex: 200,
                cursor: "pointer",
              }}
            />
          </div>
          <div className="d-flex" style={{ width: "85%", padding: "0 43px" }}>
            <div
              style={{ width: "55%" }}
              className="d-flex flex-column justify-content-center fromThePeopleTextStyling"
            >
              <div
                style={{ width: "100%" }}
                className="d-flex flex-row justify-content-between"
              >
                <span>For</span>
                <span>Ze</span>
                <span>Beoble</span>
              </div>
              <div
                style={{ width: "97%" }}
                className="d-flex flex-row justify-content-between"
              >
                <span>By</span>
                <span style={{ marginLeft: "7rem" }}>Ze</span>
                <span>Beoble</span>
              </div>
            </div>
            <div
              style={{ width: "45%", cursor: "pointer" }}
              className="d-flex justify-content-end flex-column"
              onClick={() => {
                ChangeColor("#F3F3F3");
                navigate("/aboutus");
              }}
            >
              <div style={{ width: "90%" }} className="align-self-end ">
                <h3
                  style={{
                    borderBottom: "1px solid black",
                    textAlign: "right",
                  }}
                  className="aboutUs"
                >
                  about us
                </h3>
                <p
                  className="mb-1 underLineTextStyling"
                  style={{ textAlign: "left" }}
                >
                  Cultural center for the celebration and exhibiting
                  contemporary culture and arts from MENA.
                </p>
                <p
                  className="underLineBoldTextStyling"
                  style={{ textAlign: "left" }}
                >
                  Live Music Venue · Arts & entertainment
                </p>
              </div>
            </div>
          </div>
        </Nav>
      </NavbarBs>
    </div>
  );
};

export default Navbar;
