import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import "./EditStoreText.css";
import axios from "axios";
import VerificationIcon from "./VerificationIcon";
import { baseUrl } from "../DataUrl";

interface StoreDescription {
  ar: {
    firstParagraph: string;
    secondParagraph: string;
  };
  en: {
    firstParagraph: string;
    secondParagraph: string;
  };
}

const EditStoreText: React.FC = () => {
  const [showIcon, setShowIcon] = useState(false);
  const [description, setDescription] = useState<StoreDescription>({
    ar: {
      firstParagraph: "",
      secondParagraph: "",
    },
    en: {
      firstParagraph: "",
      secondParagraph: "",
    },
  });

  const getData = async () => {
    await fetch(`${baseUrl}/api/store`)
      .then((response) => response.json())
      .then((datab) => {
        setDescription(datab);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (showIcon) {
      const timer = setTimeout(() => {
        setShowIcon(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showIcon]);

  const handleChange = (
    lang: "ar" | "en",
    e: ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setDescription({
      ...description,
      [lang]: {
        ...description[lang],
        [name]: value,
      },
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    console.log(description);

    const token = localStorage.getItem("authToken");

    try {
      const response = await axios.post(`${baseUrl}/api/store`, description, {
        headers: {
          "x-access-token": token,
        },
      });

      console.log("Response:", response.data);

      if (response.status === 200) {
        setShowIcon(true);
      }
    } catch (error) {
      console.error("Error uploading store data:", error);
    }
  };

  return (
    <>
      {showIcon && <VerificationIcon />}

      <form onSubmit={handleSubmit} className="storeform">
        <h2>Add Store Description</h2>
        <section>
          <h3>English</h3>
          <label>
            First Paragraph:
            <textarea
              name="firstParagraph"
              value={description?.en.firstParagraph}
              onChange={(e) => handleChange("en", e)}
              required
            />
          </label>
          <br />
          <label>
            Second Paragraph:
            <textarea
              name="secondParagraph"
              value={description?.en.secondParagraph}
              onChange={(e) => handleChange("en", e)}
              required
            />
          </label>
        </section>
        <section className="arabic-section">
          <h3>Arabic</h3>
          <label>
            الفقرة الأولى:
            <textarea
              name="firstParagraph"
              value={description?.ar.firstParagraph}
              onChange={(e) => handleChange("ar", e)}
              required
            />
          </label>
          <br />
          <label>
            الفقرة الثانية:
            <textarea
              name="secondParagraph"
              value={description?.ar.secondParagraph}
              onChange={(e) => handleChange("ar", e)}
              required
            />
          </label>
        </section>
        <button type="submit">Submit</button>
      </form>
    </>
  );
};

export default EditStoreText;
