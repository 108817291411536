import { useEffect, useState } from "react";
import "./ArchiveDetails.css";
import { useChangeLanguage } from "../context/ChangeLanguageContext";

type archivePropsType = {
  id: number;
  title: string;
  subTitle: string;
  imagesUrl: string[] | null;
  eventLink?: string;
};

const ArchiveDetails = ({
  id,
  imagesUrl = null,
  subTitle,
  title,
  eventLink,
}: archivePropsType) => {
  const { language } = useChangeLanguage();
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  const handleNext = () => {
    if (currentIndex !== null) {
      setCurrentIndex((prevIndex) =>
        imagesUrl !== null && prevIndex !== null && prevIndex < imagesUrl.length
          ? prevIndex + 1
          : 1
      );
    }
  };

  const handlePrev = () => {
    if (currentIndex !== null) {
      setCurrentIndex((prevIndex) =>
        prevIndex !== null && prevIndex !== 0 ? prevIndex - 1 : null
      );
    }
  };

  return (
    <>
      {imagesUrl && currentIndex ? (
        <div
          // onClick={() => setCurrentIndex(null)}
          className="imageViewer"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "rgba(0,0,0,0.8)",
            zIndex: 1111,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/x-mark-8.svg"
            onClick={() => setCurrentIndex(null)}
            style={{
              position: "absolute",
              top: "10px",
              right: "30px",
              padding: "10px",
              cursor: "pointer",
            }}
            alt="x mark"
          />
          <img
            src="/arrow-left.svg"
            onClick={handlePrev}
            style={{
              cursor: "pointer",
              zIndex: 101,
              marginRight: "0.5rem",
            }}
            alt="arrow-left"
          />
          <img
            src={imagesUrl[currentIndex - 1]}
            alt="archive"
            style={{
              maxWidth: "70%",
              maxHeight: "100%",
            }}
          />
          <img
            src="/arrow-right.svg"
            onClick={handleNext}
            style={{
              cursor: "pointer",
              zIndex: 101,
              marginLeft: "0.5rem",
            }}
            alt="arrow-right"
          />
        </div>
      ) : null}
      <div className="ArchiveMaiDiv" key={id}>
        <h1
          className="EventDetailsHeader"
          style={{
            textAlign: language === "ar" ? "right" : "left",
            direction: language === "ar" ? "rtl" : "ltr",
          }}
        >
          {title.toLocaleUpperCase()}
        </h1>
        <p
          className="EventDetailsSubHeader"
          style={{
            textAlign: language === "ar" ? "right" : "left",
            direction: language === "ar" ? "rtl" : "ltr",
          }}
        >
          {subTitle}
        </p>
        <div
          className="d-flex overflow-scroll mt-3 mt-lg-5 align-items-center scrollableDiv"
          style={{ flexDirection: language === "ar" ? "row-reverse" : "row" }}
        >
          {imagesUrl &&
            imagesUrl.map((image, i) => {
              return (
                <div className="position-relative" key={i}>
                  <img
                    key={i}
                    src={image}
                    className="EventDetailsImg"
                    onClick={() => {
                      setCurrentIndex(i + 1);
                    }}
                    alt={title}
                    style={{
                      marginLeft: language === "ar" ? "4rem" : "0",
                      marginRight: language === "ar" ? "0" : "4rem",
                    }}
                  />
                  {eventLink && i === 2 && (
                    <a
                      href={eventLink}
                      target="_blank"
                      rel="noreferrer"
                      className="youtubeLogo"
                    >
                      <img
                        src="/youtubeLogo.svg"
                        style={{ width: "55px", height: "55px" }}
                        alt="logo"
                        width={170}
                      />
                    </a>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ArchiveDetails;
